import CheckoutProduct from "../../components/checkoutProduct/CheckoutProduct";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./Checkout.css";
import { CartContext, CartContextType } from "../../context/CartContext";
import { useContext } from "react";

const Checkout = () => {
  const cart = useContext(CartContext) as CartContextType;

  return (
    <div className="checkoutPage">
      <Header />
      <div className="checkoutPageContent">
        {cart.items.length === 0 ? (
          <h3>Din varukorg är tom.</h3>
        ) : (
          <>
            <div className="checkoutProductsContainer">
              {cart.items.map((item, key) => (
                <CheckoutProduct
                  id={item.id}
                  quantity={item.quantity}
                  imgSrc={item.imgSrc}
                  key={key}
                />
              ))}
            </div>
            <div className="checkoutPageTotal">
              <h3>Totalt &#40;inkl. moms&#41;</h3>
              <h3>{cart.getTotalCost()} kr</h3>
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Checkout;
