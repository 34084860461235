import "./Product.css";
import { CartContext, CartContextType } from "../../context/CartContext";
import { useContext } from "react";

const Product = (props: { imgSrc: string }) => {
  const cart = useContext(CartContext) as CartContextType;

  return (
    <div className="product">
      <img className="productImg" src={props.imgSrc} alt=""></img>
      <button
        type="button"
        className="productBuyButton"
        onClick={() => cart.addItem(1, props.imgSrc)}
      >
        LÄGG I VARUKORG
      </button>
    </div>
  );
};

export default Product;
