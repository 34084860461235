import { ImageGenerationResponse } from "../models/responses/ImageGenerationResponse";
import api from "./api";

const ApiClient = {
  generateImages: async function (
    prompt: string,
    number: number,
    secret: string
  ): Promise<ImageGenerationResponse[]> {
    const response = await api.request({
      url: "/images",
      method: "POST",
      data: {
        prompt: prompt,
        number: number,
        secret: secret,
      },
    });

    return response.data;
  },
  getStaticImages: async function (): Promise<ImageGenerationResponse[]> {
    const response = await api.request({
      url: "/images-test",
      method: "GET",
    });

    return response.data;
  },
};

export default ApiClient;
