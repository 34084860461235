export const products = [
  {
    id: 1,
    price: 99,
    width: 21,
    height: 30,
  },
  {
    id: 2,
    price: 159,
    width: 30,
    height: 40,
  },
  {
    id: 3,
    price: 209,
    width: 40,
    height: 50,
  },
  {
    id: 4,
    price: 209,
    width: 50,
    height: 50,
  },
  {
    id: 5,
    price: 259,
    width: 50,
    height: 70,
  },
  {
    id: 6,
    price: 319,
    width: 70,
    height: 100,
  },
];
