import { useContext } from "react";
import {
  GalleryContext,
  GalleryContextType,
} from "../../context/GalleryContext";
import Product from "../product/Product";
import "./Gallery.css";

const Gallery = () => {
  const gallery = useContext(GalleryContext) as GalleryContextType;

  return (
    <div className="gallery">
      <div className="galleryRow">
        <Product imgSrc={gallery.images[0]}></Product>
        <Product imgSrc={gallery.images[1]}></Product>
        <Product imgSrc={gallery.images[2]}></Product>
      </div>
      <div className="galleryRow">
        <Product imgSrc={gallery.images[3]}></Product>
        <Product imgSrc={gallery.images[4]}></Product>
        <Product imgSrc={gallery.images[5]}></Product>
      </div>
    </div>
  );
};

export default Gallery;
