import ApiClient from "../../api/ApiClient";
import "./ImageGeneration.css";
import { useContext, useState } from "react";
import {
  GalleryContext,
  GalleryContextType,
} from "../../context/GalleryContext";

const ImageGeneration = () => {
  const gallery = useContext(GalleryContext) as GalleryContextType;
  const [isGenerating, setIsGenerating] = useState(false);
  const [imageGenerationInput, setImageGenerationInput] = useState(
    "An impressionist oil painting of sunflowers in a field"
  );
  const [secret, setSecret] = useState("");

  return (
    <div className="imageGeneration">
      <div className="imageGenerationInteraction">
        <input
          type="text"
          className="imageGenerationInput imageGenerationInputNotFocused"
          onFocus={(e) => {
            e.target.value = "";
            e.target.className =
              "imageGenerationInput imageGenerationInputFocused";
          }}
          onInput={(e) => {
            setImageGenerationInput(e.currentTarget.value);
          }}
          defaultValue="An impressionist oil painting of sunflowers in a field..."
        />
        <button
          type="button"
          className="imageGenerationButton"
          disabled={isGenerating}
          onClick={async () => {
            setIsGenerating(true);
            try {
              gallery.updateImages(
                (
                  await ApiClient.generateImages(
                    imageGenerationInput,
                    6,
                    secret
                  )
                ).map((response) => response.b64_json)
                // (await ApiClient.getStaticImages()).map(
                //   (response) => response.b64_json
                // )
              );
            } catch (exception) {
              console.log(exception);
            } finally {
              setIsGenerating(false);
            }
          }}
        >
          {isGenerating ? (
            <i className="fa fa-circle-o-notch fa-spin"></i>
          ) : (
            "Skapa"
          )}
        </button>
      </div>
      <h2>AI-genererad konst hem till dig</h2>
      <input
        type="password"
        onInput={(e) => setSecret(e.currentTarget.value)}
      />
    </div>
  );
};

export default ImageGeneration;
